@import '~/client/src/shared/theme.module';

$view-dialog-header-height: 48px;
$new-view-dialog-width: 540px;
$new-view-dialog-height: 385px;

$saved-views-dialog-filters-popup-width: 540px;
$saved-views-dialog-filters-popup-height: 400px;
$saved-views-dialog-header-height: 150px;

$EDIT_GREY_ICON: '/static/icons/edit-dark-grey.svg';
$ARROW_DOWN_ICON: '/static/icons/arrow-down.svg';

.view-dialog {
  &.view-dialog-details {
    height: fit-content !important;
  }

  left: 60px;
  position: absolute;
  top: 100%;
  z-index: 100;
  color: get_color('primary', 30);
  background: get_color('neutral', 100);
  border: 1px solid get_color('neutral', 70);
  border-radius: 8px;
  height: 560px;
  max-height: calc(100dvh - 170px);
  width: 510px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  .saved-views-details {
    height: fit-content;
    max-height: 470px;
  }

  .saved-views-filters-list {
    height: calc(100% - 65px);
  }

  .view-dialog-content {
    height: 470px;
    max-height: 470px;
  }

  .header {
    height: #{$view-dialog-header-height};
  }

  &.new-view-dialog {
    width: $new-view-dialog-width;
    max-width: $new-view-dialog-width;
    height: 540px;
    max-height: 540px;
  }

  &.saved-views-dialog {
    width: 805px;

    .search-bar-container {
      border-radius: 4px;
      background-color: get_color('neutral', 100);
    }

    .saved-views-list-header {
      border-bottom: 1px solid;
      border-color: get_color('neutral', 0, 0.08);
    }

    .saved-views-list-item {
      height: inherit;
      border-bottom: 1px solid;
      border-color: get_color('neutral', 0, 0.08);

      &:hover {
        background-color: get_color('neutral', 0, 0.08);
      }

      &.highlight {
        background-color: get_color('neutral', 0, 0.16);

        &:hover {
          background-color: get_color('neutral', 0, 0.08);
        }
      }

      .bp3-control.bp3-radio {
        flex: 0;
        padding: 0;
        margin: 0;

        input[type='radio']:focus,
        input:focus ~ .bp3-control-indicator {
          outline: none;
        }

        .bp3-control-indicator {
          margin: 12px;

          &:focus,
          &::before {
            outline: none;

            &::before {
              outline: none;
            }
          }
        }
      }

      .icon-grey svg {
        width: 20px;
        height: 20px;

        g {
          fill: get_color('neutral', 30);
        }
      }

      .saved-views-list-item-name {
        .saved-views-list-item-name-text {
          max-width: 152px;
          vertical-align: middle;
        }

        &.editable:hover:not(.editing) {
          .saved-views-list-item-name-text {
            max-width: 138px;
            padding-right: 4px;
          }

          &::after {
            width: 14px;
            height: 18px;
            content: ' ';
            background: url($EDIT_GREY_ICON) no-repeat center;
            background-size: 14px 18px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .saved-views-list-item-access {
        .saved-views-popup {
          top: 0px;
          left: 0px;
        }

        .saved-views-list-item-access-content {
          line-height: 40px;
        }
      }

      .saved-views-popup {
        position: absolute;
        z-index: 121;
        background-color: get_color('neutral', 100);
        right: 0px;
        top: 10px;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
          0px 1px 3px 0px rgba(0, 0, 0, 0.3);

        &-list {
          .saved-views-popup-list-item {
            padding: 5px 10px;
            height: 40px;

            &:hover {
              background-color: get_color('primary', 80);
            }
            &.active {
              color: get_color('neutral', 100);
              background-color: get_color('primary', 30);
            }
          }
        }
      }
      .saved-views-list-item-filters-count {
        .saved-views-list-item-filters-count-text {
          line-height: 40px;
        }
      }
    }

    .sortable-item {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      z-index: 120;
    }

    .view-dialog-content {
      max-height: calc(100% - (#{$saved-views-dialog-header-height}));
    }
  }

  &-content {
    height: calc(100% - (#{$view-dialog-header-height}));
    overflow-y: scroll;
  }

  .show-hide-icon {
    &:hover {
      svg path {
        fill: get_color('neutral', 0);
      }
    }
  }
}

.saved-views-popup {
  position: absolute;
  z-index: 121;
  background-color: get_color('neutral', 100);
  right: 0px;
  top: -10px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  &-list {
    .saved-views-popup-list-item {
      padding: 5px 10px;
      height: 40px;

      &:hover {
        background-color: get_color('primary', 80);
      }
      &.active {
        color: get_color('neutral', 100);
        background-color: get_color('primary', 30);
      }
    }
  }
}
