.sortable-item {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  z-index: 100;
  &.full {
    width: 100% !important;
    z-index: 10;
  }
}
