@import '~/client/src/shared/theme.module';

.permit-settings .form-types-list-page {
  .header-bar {
    padding: 0px !important;
    &.main {
      min-height: 56px !important;
      & > div {
        padding-top: 0px !important;
      }
    }
  }

  .form-types-list {
    $small-category-icon-size: 12px;
    $medium-category-icon-size: 16px;

    @mixin getIconProps($size, $fillColor: null) {
      height: $size;
      width: $size;

      @if $fillColor != null {
        path {
          fill: $fillColor !important;
        }
      }
    }

    .category-collapse-icon,
    .category-collapse-icon svg {
      @include getIconProps($small-category-icon-size, get_color('neutral', 0));
    }

    .forms-default-category-icon,
    .forms-default-category-icon svg {
      @include getIconProps($medium-category-icon-size);
    }

    .forms-default-category-tooltip-icon,
    .forms-default-category-tooltip-icon svg {
      @include getIconProps($small-category-icon-size);
    }

    .select-button {
      border-color: get_color('neutral', 0) !important;
      svg {
        fill: get_color('neutral', 0) !important;
      }
    }
  }

  .filter-container {
    height: 350px !important;
  }
}
