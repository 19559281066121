@import '~/client/src/shared/theme.module';

.confirm-dialog.delete-tileset {
  .bp3-dialog-header {
    display: none !important;
  }
  border: none !important;
  border-radius: 10px !important;
  width: 400px !important;
  padding-bottom: 0 !important;
}

.dialog-footer {
  .action-button {
    height: 32px;
  }
}
