@import '~/client/src/shared/theme.module';

$nav-bar-height: 32px;
$tooltip-menu-width: 170px;

.tooltip-popup {
  top: 90px;
  z-index: 14;
  right: 320px;
  background-color: get_color('neutral', 100);
  box-shadow: 0px 3px 20px -5px get_color('neutral', 50);
  .option {
    &:hover {
      background-color: get_color('primary', 96);
    }
  }
  .file-preview {
    width: 240px;
    height: 180px;
  }
  .upload-schedule-block {
    width: 240px;
    height: 160px;
    border-radius: 10px;
    border: 2px dashed get_color('neutral', 50);
    background-color: get_color('neutral', 96);
  }
  .action-button {
    height: 32px;
  }
}

.map-upload-block {
  padding: 24px;
  z-index: 12;
  border-radius: 24px;
  right: 10px;
  width: 400px;
  height: 486px;
  background-color: get_color('neutral', 100);
  box-shadow: 0px 3px 20px -5px get_color('neutral', 50);
  left: calc(50% - 180px);
  top: calc(50% - 275px);

  &.tilesets-upload {
    height: max-content;
  }

  &.fixed-top {
    top: 100px;
  }

  .submit-button {
    &.light {
      background-color: get_color('primary', 50);
      border: 1px solid get_color('primary', 50);

      &:hover {
        background-color: get_color('neutral', 100);
        color: get_color('primary', 50);
      }
    }
    border: 1px solid get_color('primary', 30);
    border-radius: 3px;
    color: get_color('neutral', 100);
    background-color: get_color('primary', 30);
    text-align: center;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      background-color: get_color('neutral', 100);
      color: get_color('primary', 30);
    }
  }

  .upload-schedule-block {
    width: 352px !important;
    height: 168px !important;
    border-radius: 5px;
    background-color: get_color('neutral', 96);
    border: 2px dashed get_color('neutral', 70) !important;
  }

  .file-preview {
    width: 300px !important;
    height: 180px !important;
  }
  .bp3-file-input {
    width: 100%;
  }
  .sitemap-icon {
    svg path {
      fill: get_color('neutral', 50);
    }
  }
}
.map-name-row {
  width: calc(100% - 12px);
  height: 48px;
  background-color: get_color('neutral', 96);
  border: 1px;
  border-bottom: 1px solid get_color('neutral', 60);
  border-radius: 4px 4px 0 0;
}
.general-map-setup {
  .filter-container-background {
    top: 0;
    background-color: get_color('neutral', 50) !important;
    width: 100%;
    height: 100%;
    z-index: 12;
  }
}
.globe-ribbon-holder {
  .edit-text-icon {
    flex-grow: 0 !important;
  }
}

.sitemap-ribbon {
  top: 55px;
  z-index: 10;
  right: 300px;
  .actions-menu {
    min-width: 30px;
    height: 30px;
    box-shadow: 0px 3px 20px -5px get_color('neutral', 50);
  }
  .title-row {
    height: 40px;
    width: 40px;
  }
  .select-dialog-container {
    min-width: 250px;
    max-width: 350px;
  }
  .delivery-attributes-container {
    display: flex;
    .delivery-attributes {
      min-width: 230px;
      .attribute-tag-holder {
        display: inline-flex !important;
        max-width: 300px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .assign-sitemap-dialog {
        top: 5px;
      }
    }
  }
  .loader-container {
    min-width: 32px;
  }
  .last-updated-container {
    max-width: 90px;
  }
}

.globe-limited-ribbon {
  right: 10px;
  top: 0;
  max-width: 200px;
  display: inline-block;

  .create-new-holder {
    min-width: 140px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 3px 20px -5px get_color('neutral', 0, 0.4);
    .save-current-view-btn {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: get_color('blue', 40);
      color: get_color('neutral', 100);
      min-width: 103px;
      cursor: pointer;
      &:hover {
        background-color: get_color('blue', 50);
        min-height: 44px;
      }
      &.disabled {
        background-color: get_color('neutral', 92);
        color: get_color('neutral', 10);
        cursor: default;
        pointer-events: none;
      }
    }
    .divider {
      height: 100%;
      min-width: 1px;
      background-color: get_color('neutral', 100);
    }
    .chevron-holder {
      &:hover {
        background-color: get_color('blue', 50);
        min-height: 44px;
      }
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: get_color('blue', 40);
      color: get_color('neutral', 100);
      min-width: 36px;
      &.disabled {
        background-color: get_color('neutral', 92);
        color: get_color('neutral', 10);
      }
    }
  }

  .reset-viewport-button {
    box-shadow: 0px 3px 20px -5px get_color('neutral', 0, 0.4);
    width: 36px;
    height: 36px;
    border-radius: 20px;
    &.disabled {
      background-color: get_color('neutral', 92);
      color: get_color('neutral', 10);
      cursor: default;
      pointer-events: none;
    }
    &:hover {
      background-color: get_color('neutral', 96);
    }
    i {
      height: 14px;
      width: 14px;
    }
  }

  .create-view-btn {
    box-shadow: 0px 3px 20px -5px get_color('neutral', 0, 0.4);
    border: 1px solid get_color('neutral', 60);
  }
  .actions-menu {
    min-width: 30px;
    height: 30px;
    box-shadow: 0px 3px 20px -5px get_color('neutral', 50);
  }
  .title-row {
    height: 30px;
    box-shadow: 0px 3px 20px -5px get_color('neutral', 50);
  }
  .select-dialog-container {
    min-width: 250px;
    max-width: 350px;
  }
  .delivery-attributes-container {
    display: flex;
    .delivery-attributes {
      min-width: 230px;
      .attribute-tag-holder {
        display: inline-flex !important;
        max-width: 300px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .assign-sitemap-dialog {
        top: 5px;
      }
    }
  }
  .loader-container {
    min-width: 32px;
  }
  .last-updated-container {
    max-width: 90px;
  }
}

.bp3-dark .bp3-popover-arrow-fill {
  fill: get_color('neutral', 30) !important;
}
.tooltip-menu {
  background-color: get_color('neutral', 30);
  width: $tooltip-menu-width;
  top: 30px;
  left: -130px;
  z-index: 2;
  .tooltip-menu-option {
    max-width: $tooltip-menu-width;
    background-color: get_color('neutral', 30);
    height: $nav-bar-height;
    .info {
      path {
        fill: get_color('neutral', 100);
      }
    }
    .delete {
      path {
        fill: get_color('neutral', 100);
      }
    }
    .map {
      g {
        fill: get_color('neutral', 100);
      }
    }
  }
}

.globe-ribbon {
  &.bp3-dialog {
    max-width: 900px !important;
    min-width: 900px !important;
    max-height: 700px !important;
  }
}

.globe-select-loader {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  z-index: 101;
}

.globe-select-table {
  height: 600px !important;
  .ReactVirtualized__Grid,
  .ReactVirtualized__Table__Grid {
    outline: none;
  }
}

.view-create-model {
  width: 220px;
  height: 40px;
  box-shadow: 0px 1px 3px 0px get_color('neutral', 0, 0.3);
  top: 45px;
  position: absolute;
  right: 0;
  z-index: 10000;
}

.globe-image-holder {
  height: 77px !important;
  width: 120px !important;
  margin-left: 5px !important;
  .file-preview {
    height: 77px !important;
    width: 120px !important;
  }
}

.globe-select-menu {
  max-width: 900px;
  min-width: 900px;
  max-height: 600px;
  min-height: 600px;

  .location-association-modal {
    position: absolute;
    max-height: 600px;
    background-color: get_color('neutral', 100);
    left: -190px;
    top: 12px;
    z-index: 101;
    width: 375px;
    border-radius: 4px;
    box-shadow: 0 20px 50px -20px get_color('neutral', 50);
  }

  .editable-label {
    &:hover {
      background-color: get_color('neutral', 96);
    }
  }
  .ReactVirtualized__Table__rowColumn {
    height: 100%;
    margin: 0 !important;
  }
  .globe-select-content {
    width: 100%;
    height: 646px;
  }
  .globe-select-header {
    height: 54px;
    background-color: get_color('neutral', 92);

    &-text {
      &:hover {
        background-color: get_color('neutral', 96);
      }
    }
    &-icon {
      svg path {
        fill: get_color('neutral', 50);
      }
    }

    .search-bar {
      width: 30%;
      .bare-input-appearance {
        padding-left: 35px;
        background-color: get_color('neutral', 92);
        &:focus {
          background-color: get_color('neutral', 96);
        }
      }
      .search-bar-icon {
        left: 10px;
        z-index: 10;
        top: 17px;
        svg path {
          fill: get_color('neutral', 80);
        }
      }
    }
  }
}

.map-view-table {
  .ReactVirtualized__Table {
    max-width: fit-content;

    &__row {
      &:hover {
        background-color: get_color('primary', 92, 0.5);
        box-shadow: 0 4px 5px 0 get_color('neutral', 0, 0.2),
          0 1px 10px 0 get_color('neutral', 0, 0.1),
          0 2px 4px -1px get_color('neutral', 0, 0.2);
      }
    }

    &__rowColumn {
      height: 100%;
      margin: 0 !important;
    }
  }
}

.globe-list-container {
  overflow: auto hidden;
  .header-row {
    display: none;
  }
  .mapboxgl-marker {
    display: none;
  }
  .mapboxgl-control-container {
    display: none;
  }
  .setup-form-map-buttons {
    display: none;
  }
  .setup-form-map-container {
    margin-left: 0 !important;
    height: 77px;
    width: 120px;
  }

  .expanded-sitemaps-list {
    max-height: 40px;
    margin-left: 20px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: get_color('primary', 30);
    }
  }

  .ReactVirtualized__Table {
    max-width: fit-content;

    &__row {
      &:hover {
        background-color: get_color('primary', 92, 0.5);
      }
    }

    &__rowColumn {
      height: 100%;
      margin: 0 !important;
    }
  }

  .company-name-cell {
    .edit-icon {
      display: none;
    }

    &:hover .edit-icon {
      display: block;
    }
  }
}
