@import '~/client/src/shared/theme.module';

$calendar-control-navigation-size: 20px;
$scrollable-section-width: 100px;
$scroll-resizer-width: 8px;
$min-header-bar-height: 65px;

.header-bar.row {
  flex-wrap: wrap;

  &.main {
    min-height: $min-header-bar-height;

    & > div {
      padding-top: 5px;
    }
  }

  .location-filter,
  .logistic-filter {
    flex: 1;
  }

  .right-bar {
    min-height: 50px !important;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 500px;
  }

  .scrollable-section {
    min-width: $scrollable-section-width;
    overflow-x: auto;
    margin-left: 15px;

    &::-webkit-scrollbar {
      width: $scroll-resizer-width;
      height: $scroll-resizer-width;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: get_color('primary', 30);
    }
  }
}

.gant-header-btn {
  height: 32px;
  background-color: get_color('primary', 30);
  border-radius: 4px;
  color: get_color('neutral', 100);
  cursor: pointer;

  &-active {
    box-shadow: 0 0 0 4px get_color('primary', 90);
  }

  &-outline {
    background-color: get_color('neutral', 100) !important;
    border: solid 1px get_color('neutral', 84);
    color: get_color('neutral', 50) !important;

    &:hover {
      background-color: get_color('primary', 88, 0.5);
    }
  }

  &.bold-font {
    font-weight: 600;
  }
}

.btn-filter {
  text-align: center;
  max-width: 250px;

  span {
    max-width: 250px;
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.calendar-control {
  .left-navigation {
    svg path {
      fill: get_color('neutral', 40);
    }
    background-color: get_color('neutral', 100);
    border-right: 0;
    border-bottom: 0;
    width: $calendar-control-navigation-size;
    height: $calendar-control-navigation-size;
    min-width: 0;
  }

  .right-navigation {
    svg path {
      fill: get_color('neutral', 40);
    }
    display: flex;
    width: $calendar-control-navigation-size;
    height: $calendar-control-navigation-size;
    cursor: pointer;
  }

  .zone-map-view-calendar {
    margin-right: $calendar-control-navigation-size;
    background-color: get_color('neutral', 100) !important;
    color: get_color('primary', 30) !important;
    font-weight: 600;
  }
}

.date-handle-button {
  min-width: 145px;
  max-width: 145px;
  background-color: get_color('neutral', 100);

  span {
    padding: 0 5px;
  }

  &.current-lookahead {
    background-color: get_color('secondary', 96);
  }
}
